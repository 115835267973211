import styled from 'styled-components';

import variables from '@splunk/themes/variables';

const PageTitle = styled.h1`
  font-size: 55px;
  color: ${variables.contentColorActive};
  font-weight: ${variables.fontWeightBold};
  margin: 0;
`;

const HeroTitle = styled.h1`
  font-size: 52px;
  color: ${variables.contentColorActive};
  font-weight: ${variables.fontWeightBold};
  margin-bottom: 80px;
`;

const PageHeading = styled.h2`
  font-size: 40px;
  color: ${variables.contentColorActive};
  font-weight: ${variables.fontWeightBold}};
  margin: 0;
`;

//This is similar to subSectionH3 but exists for SEO purposes
const SubSectionH1 = styled.h1`
  font-size: 30px;
  color: ${variables.contentColorActive};
  font-weight: ${variables.fontWeightBold};
  line-height: 40px;
  margin: 0;
`;

const SubSectionH3 = styled.h3`
  font-size: 30px;
  color: ${variables.contentColorActive};
  font-weight: ${variables.fontWeightBold};
  line-height: 40px;
  margin: 0;
`;

const SubSectionH4 = styled.h4`
  font-size: 21px;
  color: ${variables.contentColorActive};
  font-weight: ${variables.fontWeightBold};
  margin: 0;
`;

const SubSectionH5 = styled.h5`
  font-size: 18px;
  color: ${variables.contentColorActive};
  font-weight: ${variables.fontWeightBold};
  margin: 0;
`;

const SubSectionH6 = styled.h6`
  margin: 0;
  font-size: 16px;
  color: ${variables.contentColorActive};
  font-weight: ${variables.fontWeightBold};
  margin: 0;
`;

const SubHeadLarge = styled.h1`
  font-size: 30px;
  color: ${variables.contentColorActive};
  margin: 0;
`;

const SubHeadMedium = styled.h2`
  font-size: 24px;
  color: ${variables.contentColorActive};
  margin: 0;
`;

const SubHeadSmall = styled.p`
  font-size: 21px;
  color: ${variables.contentColorActive};
  margin: 0;
`;

const BodyLarge = styled.p`
  font-size: 21px;
  color: ${variables.contentColorDefault};
  padding-top: 10px;
  margin: 0;
`;

const BodyRegular = styled.p`
  font-size: 18px;
  color: ${variables.contentColorDefault};
  margin: 0;
`;

const BodySmall = styled.p`
  font-size: 15px;
  color: ${variables.contentColorDefault};
  margin: 0;
`;

const BodySmaller = styled.div`
  font-size: 12px;
  color: ${variables.contentColorDefault};
  margin: 0;
`;

const InfoCardHeader = styled.div`
  color: ${variables.contentColorActive};
  line-height: 16px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: ${variables.fontWeightSemiBold};
`;

const InfoCardText = styled.div`
  font-size: 12px;
  font-weight: ${variables.fontWeightSemiBold}
  line-height: 16px;
  color: ${variables.contentColorDefault};
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

const Eyebrow = styled.p`
  font-size: 14px;
  font-weight: ${variables.fontWeightSemiBold}
  color: ${variables.contentColorDefault};
  margin: 0;
  text-transform: uppercase;
`;

const SearchSubTitle = styled.p`
  font-size: 14px;
  color: ${variables.contentColorActive};
  margin: 0;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const SearchBodySmall = styled.div`
  font-size: 12px;
  color: ${variables.contentColorDefault};
  margin: 0;
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  p > a {
    color: ${variables.linkColor};
  }
`;

const AppDetailsSmall = styled.div`
  font-size: 12px;
  color: ${variables.contentColorDefault};
  margin: 0;
  line-height: 16px;
`;

const AppCardText = styled.div`
  font-size: 12px;
  line-height: 16px;
`;

const AppCardAuthor = styled.div`
  font-size: 11px;
  line-height: 16px;
`;

const AppCardPlatform = styled.div`
  font-weight: ${variables.fontWeightSemiBold};
  font-size: 10px;
  text-transform: uppercase;
`;

const ContentLink = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
`;

const TextLink = styled.a`
  font-size: 12px;
  color: ${variables.linkColor};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
`;

export {
  PageTitle,
  HeroTitle,
  PageHeading,
  SubSectionH1,
  SubSectionH3,
  SubSectionH4,
  SubSectionH5,
  SubSectionH6,
  SubHeadLarge,
  SubHeadMedium,
  SubHeadSmall,
  BodyLarge,
  BodyRegular,
  BodySmall,
  BodySmaller,
  InfoCardHeader,
  InfoCardText,
  Eyebrow,
  SearchSubTitle,
  SearchBodySmall,
  AppDetailsSmall,
  AppCardText,
  AppCardAuthor,
  AppCardPlatform,
  ContentLink,
  TextLink,
};
