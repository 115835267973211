import styled from 'styled-components';

import variables from '@splunk/themes/variables';

import Image from 'next/image';

import { getResourceUrl } from '../../lib/resource_helper';
import { Divider } from '../../styles/layout';
import { ContentLink } from '../../styles/typography';

const FooterMain = styled.footer`
  background-color: ${variables.backgroundColorNavigation};
`;
const FooterContainer = styled.div`
  margin: 0px auto;
  width: 1110px;
`;
const FooterLinksRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0;
`;
const FooterLinksColumn = styled.div`
  color: ${variables.contentColorActive};
  width: 16.66666667%;
  padding: 20px 0;
`;
const FooterLinkTitle = styled.div`
  font-size: ${variables.fontSize};
  line-height: 20px;
  text-transform: uppercase;
  margin: 40px 0px 15px 0px;
  font-weight: ${variables.fontWeightBold};
`;
const FooterLinkItem = styled.div`
  font-size: ${variables.fontSize};
  line-height: 18px;
  margin: 15px 0px;
`;
const FooterLink = styled(ContentLink)`
  opacity: 0.8;
  color: ${variables.contentColorActive};
`;
const SocialIconsRow = styled.div`
  display: flex;
  width: 15%;
  justify-content: space-between;
`;
const FooterLegalRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  color: ${variables.contentColorActive};
  font-size: ${variables.fontSizeSmall};
`;
const Copyright = styled.a`
  line-height: 14px;
  color: ${variables.contentColorActive};
  opacity: 0.8;
  text-decoration: none;
`;

const TrademarkText = styled.div`
  color: ${variables.neutral500};
  align-self: stretch;
  line-height: 15px;
`;

const CollectionText = styled(TrademarkText)`
  padding-bottom: 60px;
`;

const thisYear = new Date().getFullYear();

const Footer = () => (
  <FooterMain>
    <FooterContainer>
      <FooterLinksRow>
        <FooterLinksColumn>
          <FooterLinkTitle>COMPANY</FooterLinkTitle>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/about-splunk.html"
              id="about-splunk"
            >
              About Splunk
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/careers.html"
              id="careers"
            >
              Careers
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/about-us/global-impact.html"
              id="global-impact"
            >
              Global Impact
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/about-splunk/leadership.html"
              id="leadership"
            >
              Leadership
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/newsroom.html"
              id="newsroom"
            >
              Newsroom
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/partners.html"
              id="partners"
            >
              Partners
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/legal/splunk-policy-positions.html"
              id="splunk-policy-positions"
            >
              Splunk Policy Positions
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/legal/splunk-data-security-and-privacy.html"
              id="splunk-data-security-and-privacy"
            >
              Splunk Protects
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/enterprise-data-platform/splunk-ventures-funding.html"
              id="splunk-ventures-funding"
            >
              Splunk Ventures
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/procurement/suppliers.html"
              id="supplier-central"
            >
              Supplier Central
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/about-us/why-splunk.html"
              id="why-splunk"
            >
              Why Splunk?
            </FooterLink>
          </FooterLinkItem>
        </FooterLinksColumn>
        <FooterLinksColumn>
          <FooterLinkTitle>PRODUCTS</FooterLinkTitle>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/download.html"
              id="free-trials-and-downloads"
            >
              Free Trials & Downloads
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/products/pricing.html"
              id="pricing"
            >
              Pricing
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/products.html"
              id="view-all-products"
            >
              View All Products
            </FooterLink>
          </FooterLinkItem>

          <FooterLinkTitle>SPLUNK SITES</FooterLinkTitle>
          <FooterLinkItem>
            <FooterLink target="_blank" rel="noopener noreferrer" href="https://conf.splunk.com/" id="conf">
              .conf
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.splunk.com/Documentation"
              id="documentation"
            >
              Documentation
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink target="_blank" rel="noopener noreferrer" href="https://investors.splunk.com/" id="investor">
              Investor Relations
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/training.html"
              id="training"
            >
              Training & Certification
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.mypromomall.com/splunk"
              id="t-shirt"
            >
              T-Shirt Store
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/resources/videos.html"
              id="videos"
            >
              Videos
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/resources.html"
              id="view-all-resources"
            >
              View All Resources
            </FooterLink>
          </FooterLinkItem>
        </FooterLinksColumn>
        <FooterLinksColumn>
          <FooterLinkTitle>CONTACT SPLUNK</FooterLinkTitle>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/about-splunk/contact-us.html"
              id="contact-sales"
            >
              Contact Sales
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.splunk.com/en_us/about-splunk/contact-us.html#customer-support"
              id="contact-support"
            >
              Contact Support
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkTitle>SPLUNK MOBILE</FooterLinkTitle>
          <FooterLinkItem>
            <a href="https://apps.apple.com/us/app/splunk-mobile/id1420299852" target="_blank" rel="noreferrer">
              <Image
                src={getResourceUrl('/social/app-store-mobile.svg')}
                width="121"
                height="41"
                alt="Splunk Mobile - App Store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.splunk.android.alerts"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src={getResourceUrl('/social/google-play-mobile.svg')}
                width="120"
                height="37"
                alt="Splunk Mobile - Google Play"
              />
            </a>
          </FooterLinkItem>
        </FooterLinksColumn>
      </FooterLinksRow>
      <Divider></Divider>
      <FooterLinksRow>
        <div>
          <Image src={getResourceUrl('/logo.svg')} width="98" height="30" alt="Footer Logo" />
        </div>
        <SocialIconsRow>
          <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/splunk">
            <Image src={getResourceUrl('/social/twitter.svg')} width="18" height="15" alt="Twitter Icon" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="http://www.facebook.com/splunk">
            <Image src={getResourceUrl('/social/fb.svg')} width="18" height="15" alt="Facebook Icon" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/splunk">
            <Image src={getResourceUrl('/social/linkedin.svg')} width="18" height="15" alt="LinkedIn Icon" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/user/splunkvideos">
            <Image src={getResourceUrl('/social/youtube.svg')} width="18" height="15" alt="YouTube Icon" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/splunk/">
            <Image src={getResourceUrl('/social/ins.svg')} width="18" height="15" alt="Instagram Icon" />
          </a>
        </SocialIconsRow>
      </FooterLinksRow>
      <Divider></Divider>
      <FooterLegalRow>
        <div>
          <Copyright
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.splunk.com/en_us/site-map.html"
            id="sitemap"
          >
            Sitemap |{' '}
          </Copyright>
          <Copyright
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.splunk.com/view/SP-CAAAAAG"
            id="privacy"
          >
            Privacy |{' '}
          </Copyright>
          <Copyright
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.splunk.com/view/SP-CAAAAAH"
            id="terms-of-use"
          >
            Website Terms of Use |{' '}
          </Copyright>
          <Copyright
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.splunk.com/en_us/legal/splunk-terms-overview.html"
            id="licensing-terms"
          >
            Splunk Licensing Terms |{' '}
          </Copyright>
          <Copyright
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.splunk.com/view/SP-CAAAGSX"
            id="export-control"
          >
            Export Control |{' '}
          </Copyright>
          <Copyright
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.splunk.com/pdfs/legal/splunk-modern-slavery-act-transparancy-statement.pdf"
            id="modern-slavery-statement"
          >
            Modern Slavery Statement |{' '}
          </Copyright>
          <Copyright
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.splunk.com/en_us/legal/patents.html"
            id="patents"
          >
            Splunk Patents
          </Copyright>
        </div>
        <Copyright>© 2005-{thisYear} Splunk Inc. All rights reserved.</Copyright>
      </FooterLegalRow>
      <FooterLegalRow>
        <TrademarkText>
          Splunk, Splunk{'>'}, Turn Data Into Doing, Data-to-Everything, and D2E are trademarks or registered trademarks
          of Splunk Inc. in the United States and other countries. All other brand names, product names, or trademarks
          belong to their respective owners.
        </TrademarkText>
      </FooterLegalRow>
      <FooterLegalRow>
        <CollectionText>
          Selections of apps called &quot;Collections&quot; are provided as a convenience and for informational purposes
          only; an app&apos;s inclusion as part of a Collection does not constitute an endorsement by Splunk, Inc. of
          any non-Splunk developed apps.
        </CollectionText>
      </FooterLegalRow>
    </FooterContainer>
  </FooterMain>
);

export default Footer;
