const allowedUsers = [
  'dmondzik_splunk',
  'amajmurek_splunk',
  'bkawa_splunk',
  'mzgliczynski_splunk',
  'splunkbase-testuser_splunk', // admin test user
  'splunkbasetestuser@gmail.com', // no admin test user
  'tpawlowski_splunk',
  'dtaborski_splunk',
];

export const userAllowedAM = (user: string) => {
  return allowedUsers.includes(user);
};
