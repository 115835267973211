export const defaultCollectionAppCount = 3;
export const homeCollectionsDisplayCount = 4;

export const typesMap = {
  app: 'APP',
  addon: 'ADD-ON',
  connector: 'CONNECTOR',
};

export const PageSize = 18;
export const RankDisplaySize = 50;
export const DISCOVER_APPS = 'Discover Apps';
export const ALL = 'all';
export const RELEVANCE = 'relevance';
export const POPULAR = 'popular';
export const SortByNew = 'latest';

export const accentureCloudFirst = 'Cloud First, value first';
export const accentureLearnMore = 'Learn More';
export const accentureLearnMoreLink = 'https://www.accenture.com/us-en/cloud/services-index';
export const UserDataFields =
  'id,username,apps,apps_editable,apps_subscribed,apps_entitlements,api_tos_accepted,splunk_employee,is_admin';
export const RecaptchaPublicKey = '6LdeeAoTAAAAAACxXNx4ZVbt100dXBYq_9TBrf1d';

export const cookieMap: Record<string, string> = {
  'local.splunkbase.splunk.com': 'play-splunkbase-lli', // When testing locally against prod, change to splunkbase-lli
  'dev.splunkbase.splunk.com': 'play-splunkbase-lli', // When testing locally against prod, change to splunkbase-lli
  'stage.splunkbase.splunk.com': 'stage-splunkbase-lli',
  'prod.splunkbase.splunk.com': 'splunkbase-lli',
  'splunkbase.splunk.com': 'splunkbase-lli',
};

export const classicHost = process.env.NEXT_PUBLIC_CLASSIC_HOST;
export const splunkbaseHost = process.env.NEXT_PUBLIC_SPLUNKBASE_HOST;

export const getConfig = () => {
  const API_ROOT_URL =
    process.env.NEXT_RUNTIME == 'nodejs' &&
    process.env.NEXT_IN_CLUSTER_COMMUNICATION == 'true' &&
    process.env.npm_lifecycle_event !== 'build'
      ? process.env.NEXT_CLUSTER_API_ROOT
      : process.env.NEXT_PUBLIC_API_ROOT;

  return {
    API_ROOT_URL,
    APIv3_ROOT_URL: process.env.NEXT_PUBLIC_APIv3_ROOT,
    API_STATIC_URL: process.env.NEXT_PUBLIC_API_STATIC,
    IMAGE_URL: process.env.NEXT_IMAGE_URL,
    SKIP_BUILD_STATIC_GENERATION: process.env.SKIP_BUILD_STATIC_GENERATION,
    NOTIFICATION_BANNER_ENABLED: process.env.NOTIFICATION_BANNER_ENABLED,
    PINO_LOG_LEVEL: process.env.PINO_LOG_LEVEL,
    NEXT_RUNTIME: process.env.NEXT_RUNTIME,
    ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
    RESOURCE_URL: process.env.NEXT_RESOURCE_URL,
  };
};

export const isInBrowser = () => !process.env.NEXT_RUNTIME;
