import axios, { AxiosRequestConfig } from 'axios';

import { getConfig } from '../lib/global';

const { API_ROOT_URL } = getConfig();

export const axiosInstance = axios.create({
  baseURL: API_ROOT_URL,
  withXSRFToken: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  withCredentials: true,
});

const fetcher = async <TResponse>(url: string, config?: AxiosRequestConfig): Promise<TResponse> =>
  await axiosInstance.get(url, config).then((res) => res.data);

export const postSingle = async <TResponse, TBodyData>(path: string, bodyData: TBodyData): Promise<TResponse> => {
  const { data } = await axiosInstance.post<TResponse>(path, bodyData);

  return data;
};

export default fetcher;
