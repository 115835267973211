import axios from 'axios';

import { Filter } from '../../../types/splunkbase_types';
import { getConfig } from '../../global';

const { API_ROOT_URL } = getConfig();

type FilterAPIResponse = {
  key: string;
  label: string;
  options?: FilterAPIResponse[];
};
type FiltersAPIResponse = FilterAPIResponse[];
const filterAPIResponseToFilter = (filterResponse: FilterAPIResponse): Filter => {
  const keyLabelOfFilter = {
    key: filterResponse.key,
    label: filterResponse.label,
  };
  return filterResponse.options
    ? { ...keyLabelOfFilter, options: filterResponse.options.map(filterAPIResponseToFilter) }
    : keyLabelOfFilter;
};
export async function getFilters(): Promise<Filter[]> {
  try {
    const response = await axios.get<FiltersAPIResponse>(`${API_ROOT_URL}/api/v2.1/apps/filters`, {
      timeout: 2000,
    });
    return response.data.map(filterAPIResponseToFilter);
  } catch (err) {
    console.error(err);
    throw new Error(`Failed to load filters ${err}`);
  }
}
