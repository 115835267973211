import axios from 'axios';

import { Suggestion } from '../../../types/splunkbase_types';
import { getConfig } from '../../global';

const { APIv3_ROOT_URL } = getConfig();

export type SuggestionRequestParams = {
  q: string;
};

type SuggestionAPIResponse = {
  id: number;
  text: string;
  icon_url: string;
};

const suggestionAPIResponseToSuggestion = (suggestionResponse: SuggestionAPIResponse): Suggestion => ({
  id: suggestionResponse.id,
  text: suggestionResponse.text,
  icon_url: suggestionResponse.icon_url,
});

export async function fetchSuggestions(params: SuggestionRequestParams, signal?: AbortSignal): Promise<Suggestion[]> {
  const response = await axios.get<SuggestionAPIResponse[]>(`${APIv3_ROOT_URL}/suggestions`, {
    params: {
      ...params,
    },
    signal,
  });
  return response.data.map(suggestionAPIResponseToSuggestion);
}
