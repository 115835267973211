import styled from 'styled-components';

import { PropsWithChildren } from 'react';

import { contentColors } from '../../styles/layout';
import { device } from '../../styles/media_setting';
import Footer from '../Footer/Footer';
import NavBar from '../NavBar/NavBar';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import PreviewBanner from '../PreviewBanner/PreviewBanner';

const StylePage = styled.div`
  background-color: ${contentColors.backgroundColorPage};
  @media ${device.mobileS} {
    width: 1440px;
    margin: 0 auto;
  }
  @media ${device.laptopL} {
    width: 100%;
  }
`;

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <StylePage>
      <NotificationBanner />
      <PreviewBanner />
      <NavBar />
      {children}
      <Footer />
    </StylePage>
  );
};

export default Layout;
