import styled from 'styled-components';

const contentColors = {
  linkColor: '#0086FF',
  dividerGrey: '#6D6F76',
  backgroundColorPage: '#08090A',
  backgroundColorPageSecondary: '#111215',
  communityCardColor: '#272A2F',
  splunkCardColor: '#1A1C20',
  neutral100: '#393B41',
  featuredCardTagColor: '#0070f3',
  arrowColor: '#27292E',
  popoverColor: '#27292E',
  cardBorderColor: '#33343B',
};

const contentGradient = {
  featuredCardGradient:
    'linear-gradient(90deg, rgba(0, 0, 0, 0.855) 19.85%, rgba(0, 0, 0, 0.665) 44.49%, rgba(0, 0, 0, 0.19) 115.09%)',
  popoverGradient:
    'linear-gradient(270deg, #ED0080 23.65%, #EE1D62 35.15%, #F05A22 61.85%, #F16221 68.35%, #F4781F 78.35%, #F99D1C ' +
    '90.7%, #F99C1C 91.05%) 1',
  dividerGradient:
    'linear-gradient(90deg, #f99d1c 8.95%, #f99c1c 9.36%, #f4781f 21.65%, #f16221 31.74%, #f05a22 38.16%, #ee1d62 ' +
    '64.84%, #ed0080 76.34%) 1',
  filterDividerGradient: 'linear-gradient(90deg, #49e9e6, #5b58ff) 1',
  paginationGradient:
    'linear-gradient(90deg, rgba(249, 157, 28, 1) 9.36%, rgba(244, 120, 31, 1) 21.65%, rgba(241, 98, 33, 1) 31.74%, ' +
    'rgba(240, 90, 34, 1) 38.16%, rgba(238, 29, 98, 1) 64.84%, rgba(237, 0, 128, 1) 76.34%)',
};

const MainContainer = styled.div`
  margin: 0 auto;
  width: 1110px;
`;

const MainNewAppContainer = styled.div`
  margin: 0 auto;
  width: 1254px;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 30px;
`;

const PageHeader = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 48px auto;
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  width: 1110px;
`;

const Divider = styled.hr`
  border-top: 1px solid ${contentColors.neutral100};
  opacity: 0.2;
`;

export {
  contentColors,
  contentGradient,
  MainContainer,
  MainNewAppContainer,
  ContentContainer,
  PageHeader,
  PageContainer,
  Divider,
};
