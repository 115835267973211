import { format } from 'date-fns';

import TagManager from 'react-gtm-module';

import { AppDetails, AppReleaseMeta, Filter } from '../types/splunkbase_types';

interface SentGMTData {
  event: string;
  file_name?: string;
  link_url?: string;
  search_term?: string;
  search_type?: string;
}

export const getDisplayableProducts = (compatibleProducts: string[], availableProducts: Filter[]) => {
  let displayProducts = [] as string[];

  if (compatibleProducts && availableProducts && availableProducts.length > 0) {
    availableProducts.forEach((product) => {
      if (compatibleProducts.includes(product.key)) {
        displayProducts.push(product.label);
      }
    });
    if (displayProducts.length === 0) displayProducts = ['Not Available'];
    return displayProducts.join(', ');
  }
};

export const getDisplayableCategories = (appCategories: string[], availableCategories: Filter[]) => {
  const displayCategories = availableCategories
    .filter((cat) => appCategories.includes(cat.key))
    .map((cat) => cat.label);

  return displayCategories.join(', ');
};

export const getPrintedTime = (time?: string) => {
  return time ? format(new Date(time), 'MMMM d, yyyy') : '';
};

export const getCompatibleVersions = (release: AppReleaseMeta) => {
  return release?.version_compatibility?.join(', ') || '';
};

export const getCompatibleProducts = (app: AppDetails, release: AppReleaseMeta) => {
  // set up the app level products
  // @todo: BASE-13507 don't hard-code; get from filters API or something
  const splunk_cloud_pt = 'cloud';
  const soar_cloud_pt = 'soar_cloud';
  const cloud_types = [splunk_cloud_pt, soar_cloud_pt];
  const app_product_types: string[] = [];
  app.product_types?.forEach((pt) => {
    if (!cloud_types.includes(pt)) {
      app_product_types.push(pt);
    }
  });

  // now add release level products (cloud)
  const release_product_types = app_product_types;
  if (release && release.cloud_compatible) {
    if (app.product === 'splunk') {
      release_product_types.push(splunk_cloud_pt);
    } else if (app.product === 'soar') {
      release_product_types.push(soar_cloud_pt);
    }
  }
  return release_product_types;
};

export const sendGTMClickSearchResult = (search_term: string, search_type: string, link_url: string) => {
  const data = {
    event: 'click_search_result',
    search_term,
    search_type,
    link_url,
  };
  sendGTMData(data);
};

export const sendGTMViewSearchResult = (search_term: string, search_type: string) => {
  const data = {
    event: 'view_search_results',
    search_term,
    search_type,
  };
  sendGTMData(data);
};

export const sendGTMEventFileDownload = (file_name: string, link_url: string) => {
  const data = {
    event: 'file_download',
    file_name,
    link_url,
  };
  sendGTMData(data);
};

const sendGTMData = (data: SentGMTData) => {
  TagManager.dataLayer({ dataLayer: data });
};

export const generateUniqueTestId = (label: string) => {
  return label?.toLowerCase().replaceAll(' ', '_');
};
