import { getConfig } from './global';

const { API_ROOT_URL } = getConfig();

/**
 * tries to execute a function within a specified time.
 * if it exceeds the specified time it will reject the promise, otherwise will resolve and return the result
 */
export const executeWithinMs: <T>(fn: () => Promise<T>, time: number) => Promise<T> = (fn, time) =>
  new Promise((resolve, reject) => {
    const timerId = setTimeout(
      () => reject(`Function has not finished executing within time limit of ${time}ms`),
      time
    );
    const evaluated = fn();
    if (typeof evaluated.then === 'function') {
      // it's async
      evaluated.then((result) => {
        clearTimeout(timerId);
        resolve(result);
      });
    } else {
      clearTimeout(timerId);
      resolve(evaluated);
    }
  });

const calculatePathname = (pathname: string) => (typeof window !== 'undefined' ? pathname : '');

const getRedirectURL = () =>
  typeof window !== 'undefined' && window.location.origin?.length > 1 ? window.location.origin : '';

export const getLogInUrl = (pathname: string) => {
  return `${API_ROOT_URL}/limelight-login/?return=${getRedirectURL()}${calculatePathname(pathname)}/`;
};

export const getLogOutUrl = (pathname: string) => {
  return `${API_ROOT_URL}/limelight-logout/?return=${getRedirectURL()}${calculatePathname(pathname)}/`;
};
