import { createContext } from 'react';

import { Filter, UserAuthData, UserMeta } from '../types/splunkbase_types';

export interface ProductProps {
  availableProducts: Filter[];
  availableCategories: Filter[];
}

export interface AuthProps {
  userAuthData: UserAuthData;
  userMeta: UserMeta;
  exposeAppManagement: boolean;
  isLoggedIn: boolean;
  isLoading: boolean;
}

const ProductContext = createContext<ProductProps>({
  availableProducts: [],
  availableCategories: [],
});

const AuthContext = createContext<AuthProps>({
  userAuthData: {} as UserAuthData,
  userMeta: {} as UserMeta,
  exposeAppManagement: false,
  isLoggedIn: false,
  isLoading: true,
});

export { ProductContext, AuthContext };
