/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import _ from 'lodash';
import styled from 'styled-components';

import Popover from '@splunk/react-ui/Popover';
import Text from '@splunk/react-ui/Text';

import { useRouter } from 'next/router';

import React, { useCallback, useState } from 'react';

import { fetchSuggestions } from '../../lib/api/api_v3/suggestions';
import { sendGTMViewSearchResult } from '../../lib/sharedFunctions';
import { device } from '../../styles/media_setting';
import { Suggestion } from '../../types/splunkbase_types';
import Autocomplete from '../Autocomplete/Autocomplete';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const SearchStyle = styled(Text)`
  height: 40px;
  @media ${device.contentW} {
    width: 445px;
  }
`;

let cancelToken: AbortController;

const NavBarSearch = () => {
  const [query, setQuery] = useState('');
  const [, setSearchQuery] = useState({});

  const [searchSuggestions, setSearchSuggestions] = useState<Suggestion[]>([]);
  const [displayItems, setDisplayItems] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<React.Component | HTMLElement | null>();
  const anchorRef = useCallback((el: React.Component | HTMLElement | null) => setAnchor(el), []);
  const handleRequestClose = useCallback(() => {
    setDisplayItems(false);
    setQuery('');
  }, []);

  const router = useRouter();
  const {
    query: { categories, filters },
  } = router;

  const sendQuery = async (value: string) => {
    if (!value) {
      return;
    }

    if (typeof cancelToken !== typeof undefined) {
      cancelToken.abort();
    }

    try {
      cancelToken = new AbortController();
      const suggestions = await fetchSuggestions({ q: value }, cancelToken.signal);

      if (suggestions.length) {
        setSearchSuggestions([...suggestions]);
      }
    } catch (err) {
      if (axios.isCancel(err)) console.log(`request cancelled: ${err}`);
      else console.log('cancel error');
    }
  };

  const onSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.MouseEvent<HTMLSpanElement>
  ) => {
    const target = event.target as HTMLInputElement;
    const keyword = !!target?.value?.length ? target.value : '';

    if (!target.type || target.type !== 'text') setQuery('');
    else {
      setQuery(keyword);
      setDisplayItems(true);

      const search = _.debounce(sendQuery, 50);

      setSearchQuery((prevSearch: { cancel: () => void }) => {
        if (prevSearch.cancel) {
          prevSearch.cancel();
        }
        return search;
      });

      search(keyword);
    }
  };

  const onSubmit: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    const keycode = event.code as string;
    if (!query || !keycode || keycode !== 'Enter') {
      return;
    }

    if (keycode === 'Enter') {
      sendGTMViewSearchResult(query, 'Enter');
    }

    router.push(
      {
        pathname: '/apps',
        query: {
          keyword: query,
          ...(categories && { categories: categories }),
          ...(filters && { filters: filters }),
        },
      },
      undefined,
      { shallow: true }
    );
    setDisplayItems(false);
    setQuery('');
  };

  return (
    <>
      <SearchStyle
        data-test="textbox"
        appearance="search"
        placeholder="Find an app"
        value={query}
        onChange={onSearchChange}
        onFocus={() => {
          setDisplayItems(true);
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onKeyDown={onSubmit}
        elementRef={anchorRef}
      />
      <ErrorBoundary>
        {query && displayItems && (
          <Popover
            data-test="autocomplete"
            open={displayItems}
            anchor={anchor}
            onRequestClose={handleRequestClose}
            appearance="none"
            animation
            autoCloseWhenOffScreen={false}
            closeReasons={['escapeKey', 'tabKey']}
            style={{
              paddingLeft: '2%',
              width: '103%',
              background: 'transparent',
            }}
            placeholder=""
          >
            <Autocomplete
              onContentClick={handleRequestClose}
              searchQuery={query}
              results={searchSuggestions}
              width={445}
            />
          </Popover>
        )}
      </ErrorBoundary>
    </>
  );
};

export default NavBarSearch;
